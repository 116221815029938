<template>
  <div class="catsec-home">
    <span
      v-for="sport in sportsList"
      :key="sport.id"
      :class="['cattit-home', { active: selectedSport.id === sport.id }]"
      @click="setSportMenu(sport)"
    >
      <component
        :active="selectedSport.id === sport.id"
        v-if="isComponent(sport.icon)"
        :is="sport.icon"
      />
      <img v-else :src="sport.icon" class="menu-icon-sports-svg" />
      <span> {{ sport.displayName }} </span>
    </span>
  </div>
</template>

<script>
import SoccerIcon from "../icons/sports/SoccerIcon.vue";
import BasketballIcon from "../icons/sports/BasketballIcon.vue";
import CricketIcon from "../icons/sports/CricketIcon.vue";
import TennisIcon from "../icons/sports/TennisIcon.vue";
import DartIcon from "../icons/sports/DartIcon.vue";
import IcehockeyIcon from "../icons/sports/IcehockeyIcon.vue";
import RugbyIcon from "../icons/sports/RugbyIcon.vue";
import VolleyballIcon from "../icons/sports/VolleyballIcon.vue";
import BoxingIcon from "../icons/sports/BoxingIcon.vue";
import HandballIcon from "../icons/sports/HandballIcon.vue";
import MixedmartialartIcon from "../icons/sports/MixedmartialartIcon.vue";
import AmericafootballIcon from "../icons/sports/AmericafootballIcon.vue";
import BadmintonIcon from "../icons/sports/BadmintonIcon.vue";

export default {
  name: "sports",
  props: {
    live: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SoccerIcon,
    BasketballIcon,
    CricketIcon,
    TennisIcon,
    RugbyIcon,
    IcehockeyIcon,
    DartIcon,
    VolleyballIcon,
    BoxingIcon,
    HandballIcon,
    MixedmartialartIcon,
    AmericafootballIcon,
    BadmintonIcon,
  },
  data() {
    return {
      selectedSport: {
        id: 1,
        name: "Football",
        displayName: this.$t("football"),
        icon: "/menu/soccer.svg",
      },
      sportsList: [
        {
          id: 1,
          name: "Football",
          displayName: this.$t("football"),
          icon: "SoccerIcon",
        },
        {
          id: 2,
          name: "Basketball",
          displayName: this.$t("basketball"),
          icon: "BasketballIcon",
        },
        {
          id: 5,
          name: "Tennis",
          displayName: this.$t("tennis"),
          icon: "TennisIcon",
        },
        {
          id: 117,
          name: "MixedMartialArts",
          displayName: this.$t("mixedMartialArts"),
          icon: "MixedmartialartIcon",
        },
        {
          id: 10,
          name: "Boxing",
          displayName: this.$t("boxing"),
          icon: "BoxingIcon",
        },
        {
          id: 21,
          name: "Cricket",
          displayName: this.$t("cricket"),
          icon: "CricketIcon",
        },
        {
          id: 12,
          name: "Rugby",
          displayName: this.$t("rugby"),
          icon: "RugbyIcon",
        },
        {
          id: 16,
          name: "AmericanFootball",
          displayName: this.$t("americanFootball"),
          icon: "AmericafootballIcon",
        },
        {
          id: 4,
          name: "IceHockey",
          displayName: this.$t("iceHockey"),
          icon: "IcehockeyIcon",
        },

        // {
        //   id: 31,
        //   name: "Badminton",
        //   displayName: this.$t("badminton"),
        //   icon: "BadmintonIcon",
        // },

        {
          id: 23,
          name: "Volleyball",
          displayName: this.$t("volleyball"),
          icon: "VolleyballIcon",
        },
        {
          id: 6,
          name: "Handball",
          displayName: this.$t("handball"),
          icon: "HandballIcon",
        },

        // {
        //   id: 22,
        //   name: "Darts",
        //   displayName: this.$t("darts"),
        //   icon: "DartIcon",
        // },
      ],
    };
  },
  mounted() {
    const { sport_id } = this.$store.state;
    const defaultSport = this.sportsList.find((sport) => sport.id === sport_id);
    if (this.live) {
      this.sportsList = this.live
        ? this.sportsList.filter((sport) =>
            ["Football", "Basketball", "Tennis", "IceHockey", "Handball"].includes(
              sport.name
            )
          )
        : this.sportsList;
    }
    this.selectedSport = defaultSport;
    this.fetchData();
  },
  methods: {
    isComponent(icon) {
      // Check if the icon refers to a component
      return this.$options.components[icon] !== undefined;
    },
    fetchData: function () {
      // this.getMarket();
    },
    getKey: function (index) {
      return Math.random()
        .toString(11)
        .replace("0.", "sport-" + index + "-");
    },
    setSport: function (sportID) {
      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID", sportID);
      if (this.$store.state.current_page === "home") return;
      this.$router.push({ name: "home" });
    },

    setSportMenu: function (sport) {
      this.$store.dispatch("setFixtureFilter", 1);
      this.sport_name = sport.name;
      this.selectedSport = sport;
      this.$store.dispatch("setSportID", sport.id);
      this.$store.dispatch("setCurrentPage", sport.name);
    },
    getMenuTextClass: function (sportID) {
      return parseInt(this.$store.state.sport_id) === parseInt(sportID)
        ? "ml-2 item-wrapper-span-active"
        : "ml-2 item-wrapper-span";
    },
    isActiveMenu: function (name) {
      return this.current_page === name ? "menu-active" : "";
    },
    resetGames: function () {
      this.$store.dispatch("resetAllGames");

      if (this.current_page !== "home") {
        this.goHome();
      }
    },
  },
  computed: {
    iconSize: function () {
      return 20;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    show_freebet: function () {
      var p = this.getProfile();
      if (!p) {
        return true;
      }

      return parseInt(p.b4) > 0;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
  },
};
</script>

<style scoped>
.active {
  /* font-weight: bold;
  padding-bottom: 5px;
  border-radius: 4px; */
  background-color: var(--slide-active);
  color: var(--slide-color-active);
  border: 1px solid var(--slide-fill);
  font-weight: bold;
}

.cattit-home svg {
  fill: var(--icons-color);
}

.cattit-home svg path {
  stroke: blue;
}

.active span {
  color: var(--slide-color-active);
}

.active svg {
  fill: var(--slide-color-active);
  /* stroke: #fefefe; */
}

/* .active svg path {
  outline: solid green;  
  fill: var(--slide-color-active);
} */

.active svg path {
  outline: solid green;
  fill: var(--slide-color-active);
  /* stroke: var(
    --slide-stroke-color
  );  */
  stroke: #fefefe;
}

.casino-col {
  border-radius: 4px;
  padding: 12px 16px;
  font-family: Game Station;
  background-color: var(--slide-fill);
  color: var(--slide-color);
  border: 1px solid var(--slide-stroke);
  font-size: 10px;
  cursor: pointer;
}

.casino-col.active {
  background-color: var(--slide-active);
  color: var(--slide-color-active);
  border: 1px solid var(--slide-fill);
  font-weight: bold;
}
</style>
